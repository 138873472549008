<template>
  <form
    ref="form"
    @submit.prevent="next"
  >
    <c-title>Persönliche Angaben</c-title>

    <p class="mt-8 mb-8" />
    <c-select
      v-model="fields.salutation"
      :errors="errors.salutation"
      class="mb-8"
      label="Anrede"
      autocomplete="honorific-suffix"
    />
    <c-form-text
      id="name"
      v-model="fields.name"
      :errors="errors.name"
      class="mb-8"
      label="Vorname"
      autocomplete="honorific-suffix"
      required
    />
    <c-form-text
      v-model="fields.surname"
      :errors="errors.surname"
      class="mb-8"
      label="Nachname"
      autocomplete="honorific-suffix"
      required
    />
    <c-form-text
      v-model="fields.contact_person"
      :errors="errors.contact_person"
      class="mb-8"
      label="Ansprechpartner*in (optional)"
      help="(wenn abweichend von Antragsteller*in)"
      autocomplete="honorific-suffix"
    />
    <c-form-text
      v-model="fields.company"
      :errors="errors.company"
      class="mb-8"
      label="Institution (optional)"
      help="(wenn der Antrag für eine Kultureinrichtung, einen Verein o. ä. erfolgt)"
      autocomplete="honorific-suffix"
    />
    <c-form-text
      v-model="fields.address_additional_info"
      :errors="errors.address_additional_info"
      class="mb-8"
      label="Evtl. Adresszusatz (optional)"
      autocomplete="honorific-suffix"
    />
    <c-form-text
      v-model="fields.address"
      :errors="errors.address"
      class="mb-8"
      label="Straße und Hausnummer"
      autocomplete="street-address"
      maxlength="100"
      required
    />
    <c-form-columns :columns="[1, 5]">
      <c-form-text
        v-model="fields.postal_code"
        :errors="errors.postal_code"
        class="mb-8"
        label="Postleitzahl"
        autocomplete="postal-code"
        inputmode="numeric"
        pattern="[0-9]*"
        minlength="5"
        maxlength="5"
        required
      />
      <c-form-text
        v-model="fields.city"
        :errors="errors.city"
        class="mb-8"
        label="Ort"
        autocomplete="address-level2"
        maxlength="100"
        required
      />
    </c-form-columns>
    <c-form-text
      v-model="fields.contact_number"
      :errors="errors.contact_number"
      class="mb-8"
      label="Telefonnummer"
      autocomplete="honorific-suffix"
      required
    />
    <c-form-text
      v-model="fields.email"
      :errors="errors.email"
      class="mb-8"
      label="E-Mail-Adresse"
      type="email"
      autocomplete="email"
      required
    />
    <c-button>
      Nächster Schritt
    </c-button>
  </form>
</template>

<script>
import BRequestStep from './BRequestStep.vue'

export default {
  name: 'VRequestStep0',

  extends: BRequestStep,

  data () {
    return {
      fields: {
        salutation: '',
        name: '',
        surname: '',
        contact_person: '',
        company: '',
        address: '',
        contact_number: '',
        email: ''
      }
    }
  },

  computed: {
    step: () => 0,
    id: () => 'reportData'
  }
}
</script>
